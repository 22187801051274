const variables = {
  assetsPrefix: 'https://hodl-assets.fly.dev/s',
  apiHost: 'https://api.hodldash.io',
  colors: {
    background: 'rgb(0, 3, 22)',
    black: 'rgb(0, 3, 23)',
    blue: 'rgb(0, 152, 255)',
    chatPurple: '#0C0F25',
    grey: 'rgb(133, 134, 143)',
    green: '#50E89D',
    marineBlue: 'rgb(17, 19, 49)',
    purple: 'rgb(36, 41, 107)',
    purpleOpac: 'rgba(36, 41, 107, 0)',
    red: '#E85050',
    white: 'rgb(237, 237, 237)',
    yellow: 'rgb(254, 212, 7)'
  },
  desktop: {
    backgroundsMediaQuery: '@media screen and (min-width: 1670px)',
    mediaQuery: '@media screen and (min-width: 901px)',
    device: {
      minWidth: '901px'
    },
    header: {
      width: '1630px'
    },
    body: {
      minWidth: '1420px'
    }
  },
  fonts: {
    default: 'Jost, Helvetica Neue, Arial, sans-serif'
  },
  scaled: {
    backgroundsMediaQuery: '@media screen and (min-width: 901px) and (max-width: 1669px)'
  },
  mobile: {
    backgroundsMediaQuery: '@media screen and (max-width: 900px)',
    mediaQuery: '@media screen and (max-width: 900px)',
    device: {
      maxWidth: '900px'
    }
  },
  mobileWidth: 900,
  scaleWidth: 1670,
  additionalBnbPaidOut: 17500,
  bnbPerNft: 0.2,
  estInvestmentFunds: 0,
  legalLinks: [
    {
      url: '/terms',
      label: 'Terms'
    },
    {
      url: '/privacy',
      label: 'Privacy'
    },
    {
      url: '/cookies',
      label: 'Cookies'
    },
    {
      url: '/disclaimers',
      label: 'Disclaimers'
    }
  ],
  links: {
    aboutbet: 'https://hodltoken.net/bet',
    aboutgames: 'https://hodltoken.net/play-to-earn',
    aboutminigames: 'https://hodltoken.net/play-to-earn#mini-games',
    abouthodlhands: 'https://hodltoken.net/hodl-hands-nfts',
    abouthodl: 'https://hodltoken.net/about-our-token',
    achievements: 'https://hodltoken.net/achievements',
    announcementsontg: 'https://t.me/HodlAnnouncements',
    appfaqs: 'https://hodltoken.net/app#faqs',
    appsecurity: 'https://hodltoken.net/app#security',
    artpack: 'https://hodl-assets.fly.dev/s/49b77a14-98b7-4d7d-a62b-331e5e048ad4',
    audit: 'https://hodl-assets.fly.dev/s/bea4cbf1-55b7-4fe4-af55-d015bbb6fc7f',
    bet: 'https://bet.hodlx.exchange/coin-toss/hodlx',
    betrangefinder: 'https://bet.hodlx.exchange/rangefinder/hodlx',
    boggedfinance: 'https://charts.bogged.finance/?c=bsc&t=0x0E9766dF73973abCfEDDE700497c57110ee5c301',
    bugbounty: 'https://hodltoken.net/bug-bounty',
    buy: 'https://pancakeswap.finance/swap?outputCurrency=0x0e9766df73973abcfedde700497c57110ee5c301',
    buybnb: 'https://hodltoken.net/buy-bnb',
    buyhodlx: 'https://hodlx.exchange/swap?outputCurrency=0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681',
    calculator: 'https://hodltoken.net/#calculator',
    claimrewards: 'https://hodltoken.net/claim',
    coinbasehodlx: 'https://www.coinbase.com/price/hodlx',
    coingecko: 'https://coingecko.com/en/coins/hodl',
    coinmarketcap: 'https://coinmarketcap.com/currencies/hodl',
    coinmarketcaphodlx: 'https://coinmarketcap.com/currencies/hodlx/',
    communityguidelines: 'https://hodltoken.net/community-guidelines',
    contract: 'https://bscscan.com/token/0x0e9766df73973abcfedde700497c57110ee5c301',
    contractCode: 'https://bscscan.com/address/0x7f7e7530f7a5f020244b60f890acc05e2c1a5aae#code',
    dashboardOverview: '/dashboard',
    dashboardRewards: '/dashboard/rewards',
    dashboardReflections: '/dashboard/reflections',
    dashboardReinvestments: '/dashboard/reinvestments',
    dashboardTransactions: '/dashboard/transactions',
    dashboardHolders: '/dashboard/hodlers',
    dashboardAddresses: '/dashboard/addresses',
    dashboardVideo: 'https://www.youtube.com/watch?v=9jFVbA5nkWM',
    decentraland: 'https://play.decentraland.org/?position=34,70',
    dex: 'https://hodlx.exchange',
    dextourvideo: 'https://hodltoken.net/videos/hodlx-platform-tour',
    dexfarm: 'https://hodlx.exchange/farms',
    dexstaking: 'https://hodlx.exchange/pools',
    dextools: 'https://www.dextools.io/app/bsc/pair-explorer/0xad8b51b66e69eb7301d30120d7edd4df96cbd174',
    discord: 'https://discord.com/invite/D666jh5C5D',
    downloadandroidapp: 'https://hodltoken.net/android-app',
    downloadappleapp: 'https://hodltoken.net/apple-app',
    elementnftmarket: 'https://www.element.market/collections/hodl-hands-nfts',
    facebook: 'https://facebook.com/HODLtokenBSC',
    farmhodl: 'https://hodlx.exchange/farms',
    gamecoincatcher: 'https://hodltoken.net/play-to-earn/coin-catcher',
    gemminer: 'https://hodltoken.net/play-to-earn/gem-miner',
    gamehodlninja: 'https://hodltoken.net/play-to-earn/crypto-slash',
    gamemoonshot: 'https://hodltoken.net/play-to-earn/moonshot',
    games: 'https://hodltoken.net/play-to-earn',
    gemfighter: 'https://hodltoken.net/gem-fighter-p2e',
    gemfighterbeta: 'http://fraktalia.org/webgl/hodlfighter/',
    gemfightercontract: 'https://bscscan.com/address/0x1dc59c50d21514895a88d10f17af8fb1017e6a37#code',
    gemfighteropensea: 'https://opensea.io/collection/gemfighternfts',
    gemfightervideos: 'https://www.youtube.com/watch?v=TIw8FZNK1HM&list=PLh50Cy8l4VJICLQUFvDEl4M3NzwGpY09J&index=1',
    gemfighteronx: 'https://twitter.com/GemFighterNFTs',
    gemviewer: 'https://hodltoken.net/gem-fighter-nfts/viewer',
    gfraritypack: 'https://hodltoken.net/gem-fighter-nfts-guide.pdf',
    github: 'https://github.com/HODL-org',
    hodlchart: 'https://www.dextools.io/app/bnb/pair-explorer/0xad8b51b66e69eb7301d30120d7edd4df96cbd174',
    hodlhandscontract: 'https://bscscan.com/token/0x7E82123bCb6465133D6E9E1Ad94d0115DE041b3D',
    hodlhandsdeck: 'https://hodltoken.net/nft-deck',
    hodlhandsnfts: 'https://hodltoken.net/hodl-hands-nfts',
    hodlhandsoverview: 'https://hodltoken.net/nft-overview',
    hodlx: 'https://hodlx.exchange',
    hodlxaudit: 'https://github.com/cyberscope-io/audits/blob/main/hodlx/audit.pdf',
    hodlxchart: 'https://www.dextools.io/app/en/bnb/pair-explorer/0xe253b0f3b8b7526314831f3abf6fedd9a44a185b',
    hodlxcontract: 'https://bscscan.com/token/0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681',
    hodlxexchanges: 'https://hodltoken.net/hodlx-exchanges',
    hodlxinfo: 'https://hodltoken.net/about-hodlx',
    hodlxonbinance: 'https://www.binance.com/en-GB/price/hodlx',
    hodlxoncoinbrain: 'https://coinbrain.com/coins/bnb-0xaebdbcc7d6821e3924ee5167b4138a43c19cf681',
    hodlxvulkania: 'https://vulkania.io/coins/hodlx',
    instagram: 'https://instagram.com/hodltoken',
    investordeck: 'https://hodltoken.net/deck',
    kyc: 'https://github.com/coinscope-co/kyc/blob/main/hodl/kyc.png',
    linkedin: 'https://www.linkedin.com/company/hodl',
    linktree: 'https://linktr.ee/hodltoken',
    liquidity: 'https://hodlx.exchange/liquidity',
    lottery: 'https://hodltoken.net/news/hodl-launches-innovative-lottery-function',
    makelppairs: 'https://hodlx.exchange/liquidity',
    medium: 'https://medium.com/@HODL-token',
    merchandise: 'https://hodlstore.io',
    moonscan: 'https://moonscan.net/token/0x0e9766df73973abcfedde700497c57110ee5c301',
    nftmintlivetwitter: 'https://twitter.com/HODL_Hands_Live',
    nftrade: 'https://nftrade.com/assets/bsc/0x7e82123bcb6465133d6e9e1ad94d0115de041b3d',
    nftscan: 'https://bnb.nftscan.com/0x7e82123bcb6465133d6e9e1ad94d0115de041b3d',
    nftsonhodlx: 'https://hodlx.exchange/nft-pools',
    nfttwitter: 'https://twitter.com/HODLHandsNFT',
    nftvideos: 'https://www.youtube.com/watch?v=a8sbIk-qKog&list=PLh50Cy8l4VJJpLxsPODlRcOOCSNc9RZYP',
    nomics: 'https://nomics.com/assets/hodlx2-hodlx',
    nomicshodlx: 'https://nomics.com/assets/hodlx2-hodlx',
    opensea: 'https://opensea.io/collection/hodl-hands-nfts',
    OurTeam: '/about#our-team',
    overviewpdf: 'https://hodltoken.net/PDF',
    playtoearnPR: 'https://finance.yahoo.com/news/dive-play-earn-games-earn-220500056.html',
    playtoearnvideo: 'https://hodltoken.net/videos/play-to-earn-gaming-coming-to-hodl',
    rareboard: 'https://www.rareboard.com/hodl-hands',
    raritypack: 'https://hodl-assets.fly.dev/s/e738a5cb-952a-40be-a4a8-7e9bf86406de',
    reddit: 'https://reddit.com/r/HodlToken',
    referhodlx: 'https://hodlx.exchange/referral',
    refinable: 'https://hodl.refinable.store/',
    rewardstacking: 'https://hodltoken.net/stacking',
    risks: 'https://hodltoken.net/cryptocurrency-investment-risks',
    rugfreecoinsauditforhodl: 'https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/HODL%20Token%20Audit.pdf',
    rugfreecoinsauditforhodlx: 'https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/HODL%20Token%20Audit.pdf',
    snapchat: 'https://www.snapchat.com/add/HodlToken',
    socialresources: 'https://hodltoken.net/resources/spread-the-word-about-hodl',
    solitaire: 'https://hodltoken.net/play-to-earn/solitaire',
    stacking: 'https://hodltoken.net/stacking',
    stakehodl: 'https://hodlx.exchange/pools',
    stakehodlxtoearnhodl: 'https://hodlx.exchange/earn',
    stakenfts: 'https://hodlx.exchange/nft-pools',
    staketoearnnfts: 'https://hodlx.exchange/earn',
    telegram: 'https://t.me/hodlinvestorgroup',
    telegramForward: 'https://t.me/HodlForward',
    telegramapp: 'https://t.me/+7eDTngCs9RM4YTc0',
    threads: 'https://www.threads.net/@hodltoken',
    tiktok: 'https://www.tiktok.com/@hodltoken',
    timeline: 'https://hodltoken.net/timeline',
    tofunft: 'https://tofunft.com/collection/hodl-hands/items',
    twitter: 'https://twitter.com/hodl_official',
    twitterhodlhands: 'https://twitter.com/HODLHandsNFT',
    twitterhodlhandslive: 'https://twitter.com/HODL_Hands_Live',
    twitterhodlx: 'https://twitter.com/official_hodlx',
    unicrypt: 'https://app.unicrypt.network/amm/pancake-v2/pair/0xad8B51b66e69EB7301d30120d7eDD4Df96CBd174',
    voteforhodl: 'https://linktr.ee/vote_hodl',
    vulkania: 'https://vulkania.io/coins/hodl',
    whatisstaking: 'https://www.coinbase.com/learn/crypto-basics/what-is-staking',
    whatisfarming: 'https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi',
    whatisimpermanentloss: 'https://hodltoken.net/resources/impermanent-loss-understanding-the-risks-and-rewards-of-liquidity-provision-in-decentralized-exchanges',
    yahoo: 'https://finance.yahoo.com/news/celebrating-three-years-hodl-pioneering-180600056.html',
    youtube: 'https://www.youtube.com/c/HODL-Official',
    youtubeapp: 'https://youtube.com/playlist?list=PLh50Cy8l4VJLFp8aFGodxtNXhrlpyVY4k&si=SrHIxX1OrD4uoBmK',
    youtubehodlx: 'https://www.youtube.com/watch?v=ganoeGt2c-c&list=PLh50Cy8l4VJIrsvgHtXCtRvk7avgLN8B9',
    youtubehub: 'https://www.youtube.com/watch?v=FIU-kGxFXu4&list=PLh50Cy8l4VJJcs8xgaXCg6sFYarRuYqAp',
    youtubenfts: 'https://www.youtube.com/watch?v=vJAOPfhGiv4&list=PLh50Cy8l4VJJpLxsPODlRcOOCSNc9RZYP',
    hodlverseinc: 'https://bestiejs.github.io/platform.js/',
    dashboard: '/dashboard',
    dashboardaddresses: '/dashboard/addresses',
    dashboardhodlers: '/dashboard/hodlers',
    dashboardreflections: '/dashboard/reflections',
    dashboardreinvestments: '/dashboard/reinvestments',
    dashboardrewards: '/dashboard/rewards',
    dashboardtransactions: '/dashboard/transactions'
  },
  navigation: [
    {
      identifier: 'key-info',
      name: 'Key Info',
      columns: [
        {
          name: 'DeFi\'s No.1 Passive Income Project',
          links: [
            {
              url: '/about',
              label: 'About',
              description: 'Passive income, anywhere, anytime',
              quicklink: false
            },
            {
              url: '/roadmap',
              label: 'Roadmap',
              description: 'See what\'s delivered and future plans',
              quicklink: false
            },
            {
              url: '/whitepaper',
              label: 'Whitepaper',
              description: 'View innovation, ecosystem, and tokenomics',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/deck',
              label: 'Investor Deck',
              description: 'Download the HODL investor pack',
              quicklink: false
            },
            {
              url: '/about#our-team',
              label: 'Team',
              description: 'Experienced and doxxed leadership',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/achievements',
              label: 'Achievements',
              description: 'Milestones delivered since May 2021',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/PDF',
              label: 'Overview PDF',
              description: '',
              quicklink: true
            },
            {
              url: 'https://t.me/HodlAnnouncements',
              label: 'Announcements',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/timeline',
              label: 'Timeline',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/cryptocurrency-investment-risks',
              label: 'Investment Risks',
              description: '',
              quicklink: true
            },
            {
              url: 'https://finance.yahoo.com/news/celebrating-three-years-hodl-pioneering-180600056.html',
              label: ' Yahoo! Finance',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: '$hodl',
      name: '$HODL',
      columns: [
        {
          name: 'The ultimate reward token',
          links: [
            {
              url: 'https://pancakeswap.finance/swap?outputCurrency=0x0e9766df73973abcfedde700497c57110ee5c301',
              label: 'Buy $HODL',
              description: 'BSC\'s highest-paying reward token (6% slippage)',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/about-our-token',
              label: 'About',
              description: '$HODL token is passive income made easy',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/#calculator',
              label: 'Calculate Rewards',
              description: 'See how much $HODL and rewards you\'ll get',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: '/exchanges',
              label: 'Where to Buy',
              description: 'See $HODL\'s DEX listings and info',
              quicklink: false
            },
            {
              url: 'https://www.dextools.io/app/bnb/pair-explorer/0xad8b51b66e69eb7301d30120d7edd4df96cbd174',
              label: 'Chart',
              description: 'View $HODL\'s chart on DEXTools',
              quicklink: false
            },
            {
              url: '/ranks',
              label: 'Rankings',
              description: 'View the $HODL investor food chain',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/claim',
              label: 'Claim BNB',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/stacking',
              label: 'Stacking',
              description: '',
              quicklink: true
            },
            {
              url: 'https://coinmarketcap.com/currencies/hodl',
              label: 'Coinmarketcap',
              description: '',
              quicklink: true
            },
            {
              url: 'https://bscscan.com/token/0x0e9766df73973abcfedde700497c57110ee5c301',
              label: 'BscScan',
              description: '',
              quicklink: true
            },
            {
              url: '/dashboard',
              label: 'Investor Dashboard',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/HODL%20Token%20Audit.pdf',
              label: 'Audit',
              description: '',
              quicklink: true
            },
            {
              url: 'https://app.unicrypt.network/amm/pancake-v2/pair/0xad8B51b66e69EB7301d30120d7eDD4Df96CBd174',
              label: 'LP Locked',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/coinscope-co/kyc/blob/main/hodl/kyc.png',
              label: 'Doxxed & KYCd ',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: '$hodlx',
      name: '$HODLX',
      columns: [
        {
          name: 'Earn more with HODLX',
          links: [
            {
              url: 'https://hodlx.exchange/swap?outputCurrency=0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681',
              label: 'Buy $HODLX',
              description: 'Boost earnings and access benefits',
              quicklink: false
            },
            {
              url: '/about-hodlx',
              label: 'About',
              description: 'Learn about $HODLX\'s utility',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange',
              label: 'Earn',
              description: 'Stake and farm to earn incredible APRs',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/hodlx-exchanges',
              label: 'Where to Buy',
              description: 'See $HODLX\'s DEX listings and info',
              quicklink: false
            },
            {
              url: 'https://www.dextools.io/app/en/bnb/pair-explorer/0xe253b0f3b8b7526314831f3abf6fedd9a44a185b',
              label: 'Chart',
              description: 'See $HODLX on DEXTools',
              quicklink: false
            },
            {
              url: 'https://bscscan.com/token/0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681',
              label: 'Contract',
              description: 'View $HODLX\'s on BscScan',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://www.dextools.io/app/en/bnb/pair-explorer/0xe253b0f3b8b7526314831f3abf6fedd9a44a185b',
              label: 'Chart',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/HODL%20Token%20Audit.pdf',
              label: 'Audit',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/coinscope-co/kyc/blob/main/hodl/kyc.png',
              label: 'Doxxed & KYCd ',
              description: '',
              quicklink: true
            },
            {
              url: 'https://twitter.com/official_hodlx',
              label: 'Twitter',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'nfts',
      name: 'NFTs',
      columns: [
        {
          name: 'A Fusion of Art, Utility & Earnings',
          links: [
            {
              url: '/hodl-hands-nfts',
              label: 'HODL Hands NFTs',
              description: 'Packed with utility & ways to earn',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/nft-deck',
              label: 'Retail Deck',
              description: 'Download HODL Hands® investor pack',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Stake HODL Hands',
              description: 'Stake HODL Hands® to earn rewards',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: '/gem-fighter-nfts',
              label: 'Gem Fighter NFTs',
              description: 'Unlock your gaming & earning potential',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/gem-fighter-nfts-guide.pdf',
              label: 'PDF Guide',
              description: 'Download Gem Fighter NFTs Game & Rarity Pack',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Stake Gem NFTs',
              description: 'Earn rewards by staking when you\'re not playing',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Swap Tokens for NFTs',
              description: '',
              quicklink: true
            },
            {
              url: 'https://twitter.com/HODLHandsNFT',
              label: 'HODL Hands® on X',
              description: '',
              quicklink: true
            },
            {
              url: 'https://twitter.com/GemFighterNFTs',
              label: 'Gem Fighter on X',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/gem-fighter-nfts/viewer',
              label: '3D gemstone viewer',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'staking',
      name: 'Staking',
      columns: [
        {
          name: 'Earn on $HODL, $HODLX and HODL Hands®',
          links: [
            {
              url: 'https://hodlx.exchange/pools',
              label: 'Staking',
              description: 'Pools for $HODL, $HODLX, & HODL Hands',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/farms',
              label: 'Yield Farming',
              description: 'Provide liquidity - enjoy big APRs',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'NFT Staking',
              description: 'More than art - HODL Hands® earn',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodlx.exchange/earn',
              label: 'Special Pools',
              description: 'Stake $HODL and $HODLX - earn NFTs',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/liquidity',
              label: 'Add Liquidity',
              description: 'Create LP pairings for farming',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/referral',
              label: 'Refer & Earn',
              description: 'Get 1% of all earnings from referrals',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/videos/hodlx-platform-tour',
              label: 'Video Tour',
              description: '',
              quicklink: true
            },
            {
              url: 'https://www.coinbase.com/learn/crypto-basics/what-is-staking',
              label: 'What\'s Staking?',
              description: '',
              quicklink: true
            },
            {
              url: 'https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi',
              label: 'What\'s Farming?',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/resources/impermanent-loss-understanding-the-risks-and-rewards-of-liquidity-provision-in-decentralized-exchanges',
              label: 'Impermanent Loss',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'play-to-earn',
      name: 'Play-to-Earn',
      columns: [
        {
          name: 'Play games - earn rewards',
          links: [
            {
              url: 'https://hodltoken.net/play-to-earn',
              label: 'About',
              description: 'Learn about our P2E ecosystem',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/crypto-slash',
              label: 'Crypto Slash',
              description: 'Slash coins - avoid bombs',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/solitaire',
              label: 'Solitaire',
              description: 'The classic card game',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/play-to-earn/gem-miner',
              label: 'Gem Miner',
              description: 'Switch and match puzzle adventure',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/coin-catcher',
              label: 'Coin Catcher',
              description: 'Grab and bag coins as they fly by',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/moonshot',
              label: 'Moonshot',
              description: 'Shot to the moon - avoid getting drifted',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/gem-fighter-p2e',
              label: 'Gem Fighter beta',
              description: '',
              quicklink: true
            },
            {
              url: 'https://bet.hodlx.exchange/coin-toss/hodlx',
              label: 'Coin Toss',
              description: '',
              quicklink: true
            },
            {
              url: 'https://bet.hodlx.exchange/rangefinder/hodlx',
              label: 'Rangefinder',
              description: '',
              quicklink: true
            },
            {
              label: 'yahoo! Finance',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'app',
      name: 'App',
      columns: [
        {
          name: 'The HODL Universe in one app',
          links: [
            {
              url: 'app',
              label: 'About',
              description: 'Learn about our innovative earnings app.',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/android-app',
              label: 'Android App',
              description: 'Download from the Google Play Store.',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/apple-app',
              label: 'Apple App',
              description: 'Coming soon to the Apple App Store.',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'basic-app-user-guide',
              label: 'Lite Mode',
              description: 'Simple access to $HODL - perfect for beginners.',
              quicklink: false
            },
            {
              url: 'pro-app-user-guide',
              label: 'Pro Mode',
              description: 'Master the full potential of the HODL app.',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/app#security',
              label: 'Security',
              description: 'Discover our world-class security.',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://youtube.com/playlist?list=PLh50Cy8l4VJLFp8aFGodxtNXhrlpyVY4k&si=SrHIxX1OrD4uoBmK',
              label: 'Videos',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/app#faqs',
              label: 'FAQs',
              description: '',
              quicklink: true
            },
            {
              url: 'https://t.me/+7eDTngCs9RM4YTc0',
              label: 'Support Chat',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'community',
      name: 'Community',
      columns: [
        {
          name: 'Become a HODLER',
          links: [
            {
              url: 'https://t.me/hodlinvestorgroup',
              label: 'Telegram',
              description: 'Chat with team and community',
              quicklink: false
            },
            {
              url: 'https://discord.com/invite/D666jh5C5D',
              label: 'Discord',
              description: 'Join community on Discord',
              quicklink: false
            },
            {
              url: 'https://twitter.com/hodl_official',
              label: 'Twitter',
              description: 'Follow official Twitter account',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://reddit.com/r/HodlToken',
              label: 'Reddit',
              description: 'Keep up-to-date on Reddit',
              quicklink: false
            },
            {
              url: '/case-studies',
              label: 'Case Studies',
              description: 'Hear from HODL\'s investors',
              quicklink: false
            },
            {
              url: '/charity',
              label: 'Charity',
              description: 'Supporting those in need',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://www.youtube.com/c/HODL-Official',
              label: 'Youtube',
              description: '',
              quicklink: true
            },
            {
              url: 'https://facebook.com/HODLtokenBSC',
              label: 'Facebook',
              description: '',
              quicklink: true
            },
            {
              url: 'https://instagram.com/hodltoken',
              label: 'Instagram',
              description: '',
              quicklink: true
            },
            {
              url: 'https://www.threads.net/@hodltoken',
              label: 'Threads',
              description: '',
              quicklink: true
            },
            {
              url: 'https://www.tiktok.com/@hodltoken',
              label: 'Tiktok',
              description: '',
              quicklink: true
            },
            {
              url: 'https://www.snapchat.com/add/HodlToken',
              label: 'Snapchat',
              description: '',
              quicklink: true
            },
            {
              url: 'https://play.decentraland.org/?position=34,70',
              label: 'Decentraland',
              description: '',
              quicklink: true
            },
            {
              url: 'https://linktr.ee/vote_hodl',
              label: 'Vote HODL',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'more',
      name: 'More',
      columns: [
        {
          name: 'Want more?',
          links: [
            {
              url: '/news',
              label: 'News',
              description: 'See the latest news from HODL',
              quicklink: false
            },
            {
              url: '/faqs',
              label: 'FAQs',
              description: 'View frequently asked questions',
              quicklink: false
            },
            {
              url: 'https://hodlstore.io',
              label: 'Merchandise',
              description: 'Grab some HODL merch',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: '/partners',
              label: 'Partners',
              description: 'See who we work with',
              quicklink: false
            },
            {
              url: '/history-of-hodl',
              label: 'Origin of HODL',
              description: 'Crypto\'s biggest word',
              quicklink: false
            },
            {
              url: '/videos',
              label: 'Videos',
              description: 'Watch videos from us',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://linktr.ee/hodltoken',
              label: 'Linktree',
              description: '',
              quicklink: true
            },
            {
              url: '/security',
              label: 'Security',
              description: '',
              quicklink: true
            },
            {
              url: '/resources',
              label: 'Resources',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/resources/spread-the-word-about-hodl',
              label: 'Social Media Assets',
              description: '',
              quicklink: true
            },
            {
              url: 'https://medium.com/@HODL-token',
              label: 'Medium',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/HODL-org',
              label: 'Github',
              description: '',
              quicklink: true
            },
            {
              url: '/first-edition-coins',
              label: '1st Edition Coins',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/bug-bounty',
              label: 'Bug Bounty',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    }
  ],
  pages: {
    index: '/',
    aboutUs: '/about',
    app: '/app',
    caseStudies: '/case-studies',
    claim: '/claim',
    news: '/news',
    events: '/events',
    resourceLibrary: '/resources',
    exchanges: '/exchanges',
    faqs: '/faqs',
    fundraising: '/fundraising',
    games: '/play-to-earn',
    gemFighternfts: '/gem-fighter-nfts',
    gemFighterP2E: '/gem-fighter-p2e',
    history: '/history-of-hodl',
    hodlxExchanges: '/hodlx-exchanges',
    hodlForward: '/charity',
    hodlRanks: '/ranks',
    nfts: '/hodl-hands-nfts',
    ourPartners: '/partners',
    roadmap: '/roadmap',
    termsOfUse: '/terms',
    hodlCoins: '/first-edition-coins',
    cookies: '/cookies',
    disclaimers: '/disclaimers',
    privacy: '/privacy',
    communityrules: '/community-guidelines',
    hodlxinfo: '/about-hodlx',
    stackingrewards: '/stacking',
    bugBounty: '/bug-bounty',
    achievements: '/achievements',
    abouthodl: '/about-our-token',
    security: '/security',
    timeline: '/timeline',
    appBasicUserGuide: 'basic-app-user-guide',
    proAppUserGuide: 'pro-app-user-guide',
    aboutApp: 'app',
    risks: 'cryptocurrency-investment-risks',
    bitmart: 'bitmart-recovery',
    videos: '/videos',
    whitepaper: '/whitepaper',
    dashboard: '/dashboard',
    dashboardAddresses: '/dashboard/addresses',
    dashboardHodlers: '/dashboard/hodlers',
    dashboardReflections: '/dashboard/reflections',
    dashboardReinvestments: '/dashboard/reinvestments',
    dashboardRewards: '/dashboard/rewards',
    dashboardTransactions: '/dashboard/transactions'
  },
  projectFunds: '0x30CaF94164160d431338dF15aEDD11Fc9025ac33 - Investment wallet\n0x9b8f934beb96f2e5ddaad90a25adecf86017a0d1 - Deployer\n0xC3f41653168501B1A0c489bf1be0D6a054E34C1A - HODL Hands owner wallet\n0x0a199377F5489A285c70f03326b6D4BF769c10d3 - Marketing wallet\n0xc18406dd23aa13a06387c84f377f01cc5c187115 - Crypto.com card payment wallet\n0x02e4097a6710a45c13367dc067fad6b6d9d6b9d5 - HODL Store wallet\n0x1Be2bf839a71Fd30d2eadd324bDF959c1Bb0235B - Exchange Team\n0x894606f1eea0e37f5537a190da291b855fe685b1 - Company wallet'
};
export default variables;